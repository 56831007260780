<template>
  <div
    class="wizard login-content login-content-signup d-flex flex-column"
    id="kt_wizard_v1"
    data-wizard-state="step-first"
    data-wizard-clickable="false"
  >
    <!--begin::Aside Top-->
    <div class="d-flex flex-column-auto flex-column px-10">
      <!--begin::Aside header-->
      <router-link :to="{ name: 'login' }" v-slot="{ href, navigate }">
        <a :href="href" @click="navigate" class="login-logo pb-lg-5 pb-10">
          <img
            src="media/logos/logo-4.png"
            class="max-h-70px"
            alt="Central Core"
          />
        </a>
      </router-link>
      <!--end::Aside header-->
      <!--begin: Wizard Nav-->
      <div class="wizard-nav py-5">
        <!--begin::Wizard Steps-->
        <div class="wizard-steps d-flex flex-column flex-sm-row">
          <!--begin::Wizard Step 1 Nav-->
          <div
            class="wizard-step flex-grow-1 flex-basis-0"
            data-wizard-type="step"
            data-wizard-state="current"
          >
            <div class="wizard-wrapper pr-7">
              <div class="wizard-icon">
                <i class="wizard-check ki ki-check"></i>
                <span class="wizard-number">1</span>
              </div>
              <div class="wizard-label">
                <h3 class="wizard-title">Compte</h3>
                <div class="wizard-desc">Informations de connexion</div>
              </div>
              <i class="wizard-arrow flaticon2-arrow2"></i>
            </div>
          </div>
          <!--end::Wizard Step 1 Nav-->
          <!--begin::Wizard Step 2 Nav-->
          <div
            class="wizard-step flex-grow-1 flex-basis-0"
            data-wizard-type="step"
          >
            <div class="wizard-wrapper pr-7">
              <div class="wizard-icon">
                <i class="wizard-check ki ki-check"></i>
                <span class="wizard-number">2</span>
              </div>
              <div class="wizard-label">
                <h3 class="wizard-title">Identité</h3>
                <div class="wizard-desc">Informations personnelles</div>
              </div>
              <i class="wizard-arrow flaticon2-arrow2"></i>
            </div>
          </div>
          <!--end::Wizard Step 2 Nav-->
          <!--begin::Wizard Step 3 Nav-->
          <div
            class="wizard-step flex-grow-1 flex-basis-0"
            data-wizard-type="step"
          >
            <div class="wizard-wrapper">
              <div class="wizard-icon">
                <i class="wizard-check ki ki-check"></i>
                <span class="wizard-number">3</span>
              </div>
              <div class="wizard-label">
                <h3 class="wizard-title">Adresse</h3>
                <div class="wizard-desc">Adresse de facturation</div>
              </div>
            </div>
          </div>
          <!--end::Wizard Step 3 Nav-->
        </div>
        <!--end::Wizard Steps-->
      </div>
      <!--end: Wizard Nav-->
    </div>
    <!--end::Aside Top-->
    <!--begin::Signin-->
    <div class="login-form">
      <!--begin::Form-->
      <form
        class="form px-10"
        novalidate="novalidate"
        id="kt_login_signup_form"
        @submit.stop.prevent="onSubmitRegister()"
      >
        <!--begin: Wizard Step 1-->
        <div data-wizard-type="step-content" data-wizard-state="current">
          <!--begin::Title-->
          <div class="pb-10 pb-lg-15 pt-lg-0 pt-5">
            <h3
              class="font-weight-bolder text-dark font-size-h2 font-size-h1-lg"
            >
              Créer un compte
            </h3>
            <div class="text-muted font-weight-bold font-size-h4">
              Vous possédez déjà un compte ?
              <router-link :to="{ name: 'login' }" v-slot="{ href, navigate }">
                <a
                  :href="href"
                  @click="navigate"
                  class="text-primary font-weight-bolder0"
                >
                  Se connecter
                </a>
              </router-link>
            </div>
          </div>
          <!--begin::Title-->
          <!--begin::Form Group-->
          <div class="form-group">
            <label class="font-size-h6 font-weight-bolder text-dark"
              >E-Mail</label
            >
            <input
              class="form-control form-control-solid h-auto py-7 px-6 border-0 rounded-lg font-size-h6"
              type="email"
              name="email"
              v-model="form1.email"
              autocomplete="off"
            />
          </div>
          <!--end::Form Group-->
          <!--begin::Form Group-->
          <div class="form-group">
            <label class="font-size-h6 font-weight-bolder text-dark"
              >Mot de passe</label
            >
            <input
              class="form-control form-control-solid h-auto py-7 px-6 border-0 rounded-lg font-size-h6"
              type="password"
              name="password"
              v-model="form1.password"
              autocomplete="off"
            />
          </div>
          <!--end::Form Group-->
          <!--begin::Form Group-->
          <div class="form-group">
            <label class="font-size-h6 font-weight-bolder text-dark"
              >Confirmer le mot de passe</label
            >
            <input
              class="form-control form-control-solid h-auto py-7 px-6 border-0 rounded-lg font-size-h6"
              type="password"
              name="cpassword"
              v-model="form1.cpassword"
              autocomplete="off"
            />
          </div>
          <!--end::Form Group-->
          <!--begin::Form Group-->
          <div class="form-group">
            <label class="checkbox mb-0">
              <input type="checkbox" name="agree" v-model="form1.agree" />
              <span class="mr-2"></span>
              J'accepte les
              <a href="#" class="ml-2">conditions d'utilisation</a>.
            </label>
          </div>
          <!--end::Form Group-->
        </div>
        <!--end: Wizard Step 1-->
        <!--begin: Wizard Step 2-->
        <div data-wizard-type="step-content">
          <!--begin::Title-->
          <div class="pt-lg-0 pt-5 pb-10">
            <h3
              class="font-weight-bolder text-dark font-size-h2 font-size-h1-lg"
            >
              Identité
            </h3>
            <div class="text-muted font-weight-bold font-size-h4">
              Informations personnelles
            </div>
          </div>
          <!--begin::Title-->
          <!--begin::Row-->

          <div class="row">
            <div class="col-xl-6">
              <!--begin::Input-->
              <div class="form-group">
                <label class="font-size-h6 font-weight-bolder text-dark">
                  Nom
                </label>
                <input
                  type="text"
                  class="form-control form-control-solid h-auto py-7 px-6 border-0 rounded-lg font-size-h6"
                  name="lastname"
                  v-model="form2.lastname"
                />
              </div>
              <!--end::Input-->
            </div>
            <div class="col-xl-6">
              <!--begin::Input-->
              <div class="form-group">
                <label class="font-size-h6 font-weight-bolder text-dark">
                  Prénom
                </label>
                <input
                  type="text"
                  class="form-control form-control-solid h-auto py-7 px-6 border-0 rounded-lg font-size-h6"
                  name="firstname"
                  v-model="form2.firstname"
                />
              </div>
              <!--end::Input-->
            </div>
          </div>
          <div class="form-group">
            <label class="font-size-h6 font-weight-bolder text-dark"
              >Type de compte</label
            >
            <select
              name="professional"
              class="form-control form-control-solid h-auto py-7 px-5 border-0 rounded-lg font-size-h6"
              v-model="form2.professional"
              @change="updateForm2Validation"
            >
              <option :value="false">Particulier</option>
              <option :value="true">Professionnel</option>
            </select>
          </div>
          <div class="row" v-if="form2.professional">
            <div class="col-xl-6">
              <!--begin::Input-->
              <div class="form-group">
                <label class="font-size-h6 font-weight-bolder text-dark">
                  Dénomination
                </label>
                <input
                  type="text"
                  class="form-control form-control-solid h-auto py-7 px-6 border-0 rounded-lg font-size-h6"
                  name="denomination"
                  v-model="form2.denomination"
                />
              </div>
              <!--end::Input-->
            </div>
            <div class="col-xl-6">
              <!--begin::Input-->
              <div class="form-group">
                <label class="font-size-h6 font-weight-bolder text-dark">
                  TVA
                </label>
                <input
                  type="text"
                  class="form-control form-control-solid h-auto py-7 px-6 border-0 rounded-lg font-size-h6"
                  name="vat"
                  v-model="form2.vat"
                />
              </div>
              <!--end::Input-->
            </div>
          </div>
          <!--end::Row-->
        </div>
        <!--end: Wizard Step 2-->
        <!--begin: Wizard Step 3-->
        <div data-wizard-type="step-content">
          <!--begin::Title-->
          <div class="pt-lg-0 pt-5 pb-10">
            <h3
              class="font-weight-bolder text-dark font-size-h2 font-size-h1-lg"
            >
              Adresse
            </h3>
            <div class="text-muted font-weight-bold font-size-h4">
              Compléter votre adresse
            </div>
          </div>
          <div class="form-group">
            <label class="font-size-h6 font-weight-bolder text-dark"
              >Adresse 1</label
            >
            <input
              type="text"
              class="form-control form-control-solid h-auto py-7 px-6 border-0 rounded-lg font-size-h6"
              name="address1"
              v-model="form3.address1"
            />
          </div>
          <div class="form-group">
            <label class="font-size-h6 font-weight-bolder text-dark"
              >Adresse 2</label
            >
            <input
              type="text"
              class="form-control form-control-solid h-auto py-7 px-6 border-0 rounded-lg font-size-h6"
              name="address2"
              v-model="form3.address2"
              placeholder="Informations complémentaires"
            />
          </div>
          <div class="row">
            <div class="col-xl-6">
              <!--begin::Select-->
              <div class="form-group">
                <label class="font-size-h6 font-weight-bolder text-dark"
                  >Pays</label
                >
                <select
                  name="country"
                  v-model="form3.country"
                  class="form-control form-control-solid h-auto py-7 px-5 border-0 rounded-lg font-size-h6"
                >
                  <option value="BE" selected>Belgique</option>
                  <option value="FR">France</option>
                  <option value="DE">Allemagne</option>
                  <option value="NL">Pays-Bas</option>
                  <option value="LU">Luxembourg</option>
                </select>
              </div>
              <!--end::Input-->
            </div>
            <div class="col-xl-6">
              <!--begin::Input-->
              <div class="form-group">
                <label class="font-size-h6 font-weight-bolder text-dark"
                  >Code postal</label
                >
                <input
                  type="text"
                  class="form-control form-control-solid h-auto py-7 px-6 border-0 rounded-lg font-size-h6"
                  name="zipcode"
                  v-model="form3.zipcode"
                />
              </div>
              <!--end::Input-->
            </div>
          </div>
          <div class="form-group">
            <label class="font-size-h6 font-weight-bolder text-dark"
              >Localité</label
            >
            <input
              type="text"
              class="form-control form-control-solid h-auto py-7 px-6 border-0 rounded-lg font-size-h6"
              name="locality"
              v-model="form3.locality"
            />
          </div>
        </div>
        <!--end: Wizard Step 3-->
        <!--begin: Wizard Actions-->
        <div class="d-flex justify-content-between pt-5">
          <div class="mr-2">
            <button
              class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
              data-wizard-type="action-prev"
            >
              Précédent
            </button>
          </div>
          <div>
            <button
              ref="kt_login_signup_submit"
              class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
              data-wizard-type="action-submit"
            >
              Créer
            </button>
            <button
              class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
              data-wizard-type="action-next"
            >
              Suivant
            </button>
          </div>
        </div>
        <!--end: Wizard Actions-->
      </form>
      <!--end::Form-->
    </div>
    <!--end::Signin-->
  </div>
</template>

<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import KTUtil from "@/assets/js/components/util";
import KTWizard from "@/assets/js/components/wizard";

export default {
  name: "register",
  data() {
    return {
      state: "signup",
      form1: {
        email: "",
        password: "",
        cpassword: "",
        agree: false
      },
      form2: {
        professional: false,
        lastname: "",
        firstname: "",
        denomination: "",
        vat: ""
      },
      form3: {
        address1: "",
        address2: "",
        countryId: "",
        zipcode: "",
        locality: ""
      }
    };
  },
  mounted() {
    // Initialize form wizard
    const wizard = new KTWizard("kt_wizard_v1", {
      startStep: 1, // initial active step number
      clickableSteps: false
    });

    // Validation before going to next page
    wizard.on("changed", () => {
      setTimeout(() => {
        KTUtil.scrollTop();
      }, 500);
    });

    // Change event
    wizard.on("change", async wizardObj => {
      switch (wizardObj.currentStep) {
        case 1:
          {
            const status = await this.fv1.validate();
            if (status === "Invalid") {
              wizardObj.goTo(1);
            }
          }
          break;
        case 2:
          {
            const status = await this.fv2.validate();
            if (status === "Invalid") {
              wizardObj.goTo(2);
            }
          }
          break;
      }
    });

    const signup_form1 = KTUtil.getById("kt_login_signup_form");
    this.fv1 = formValidation(signup_form1, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: "Ce champ est requis !"
            },
            emailAddress: {
              message: "Veuillez encoder une adresse email valide"
            }
          }
        },
        password: {
          validators: {
            notEmpty: {
              message: "Ce champ est requis !"
            }
          }
        },
        cpassword: {
          validators: {
            notEmpty: {
              message: "Ce champ est requis !"
            },
            identical: {
              compare: function() {
                return signup_form1.querySelector('[name="password"]').value;
              },
              message: "Les mots de passe ne correspondent pas !"
            }
          }
        },
        agree: {
          validators: {
            notEmpty: {
              message: "Veuillez accepter les conditions d'utilisation"
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });
    const signup_form2 = KTUtil.getById("kt_login_signup_form");
    this.fv2 = formValidation(signup_form2, {
      fields: {
        professional: {
          validators: {
            notEmpty: {
              message: "Ce champ est requis !"
            }
          }
        },
        lastname: {
          validators: {
            notEmpty: {
              message: "Ce champ est requis !"
            }
          }
        },
        firstname: {
          validators: {
            notEmpty: {
              message: "Ce champ est requis !"
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });
    const signup_form3 = KTUtil.getById("kt_login_signup_form");
    this.fv3 = formValidation(signup_form3, {
      fields: {
        address1: {
          validators: {
            notEmpty: {
              message: "Ce champ est requis !"
            }
          }
        },
        address2: {},
        country: {
          validators: {
            notEmpty: {
              message: "Ce champ est requis !"
            }
          }
        },
        zipcode: {
          validators: {
            notEmpty: {
              message: "Ce champ est requis !"
            }
          }
        },
        locality: {
          validators: {
            notEmpty: {
              message: "Ce champ est requis !"
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });
  },
  methods: {
    updateForm2Validation() {
      if (this.form2) {
        this.form2.denomination = null;
        this.form2.vat = null;
        if (this.form2.professional) {
          this.$nextTick(() => {
            this.fv2.addField("denomination", {
              validators: {
                notEmpty: {
                  message: "Ce champ est requis !"
                }
              }
            });
            this.fv2.addField("vat", {
              validators: {
                notEmpty: {
                  message: "Ce champ est requis !"
                }
              }
            });
            this.fv2.revalidateField("denomination");
            this.fv2.revalidateField("vat");
          });
        } else {
          this.fv2.removeField("denomination");
          this.fv2.removeField("vat");
        }
      }
    },
    // todo remplacer les ref par des v-model (voir uploader form)
    onSubmitRegister() {
      this.fv3.validate();

      // todo Simon : Fixer
      this.fv3.on("core.form.valid", () => {
        //   this.form3.address1 = this.$refs.address1.value;
        //   this.form3.address2 = this.$refs.address2.value;
        //   this.form3.countryId = this.$refs.countryId.value;
        //   this.form3.zipcode = this.$refs.zipcode.value;
        //   this.form3.locality = this.$refs.locality.value;
        //   // clear existing errors
        //   this.$store.dispatch(LOGOUT);
        //   // set spinner to submit button
        //   const submitButton = this.$refs["kt_login_signup_submit"];
        //   submitButton.classList.add("spinner", "spinner-light", "spinner-right");
        //   // dummy delay
        //   setTimeout(() => {
        //     // send register request
        //     this.$store
        //       .dispatch(REGISTER, {
        //         email: email,
        //         password: password
        //       })
        //       .then(() => this.$router.push({ name: "dashboard" }));
        //     submitButton.classList.remove(
        //       "spinner",
        //       "spinner-light",
        //       "spinner-right"
        //     );
        //   }, 2000);
      });
    }
  }
};
</script>
